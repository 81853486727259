<template>
    <All model="Notification" :immediate="true" ref="notificationsRef" v-slot="{data:notifications}" :query="notificationsFilter" @loading:change="(event) => loading = event">
        <SectionWrapper>
            <div class="w-full px-1 sm:px-2 xl:px-5">
                <Header :title="title"/>
                <div class="py-2 w-full flex flex-row items-between justify-center">
                    <perPageSelector @changeOption="changePerPage"/>
                    <div class="w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6">
                        <new-mod :entity="entity" @refreshDataTable="refreshDataTable"/>
                        <show-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
                        <destroy-mod v-if="elSelected" :entity="entity" @refreshDataTable="refreshDataTable"/>
                        <notificator-mod v-if="elSelected && isInteractiveAndActive" :entity="entity" @refreshDataTable="refreshDataTable"/>
                    </div>
                    <searcher @changeText="changeText"/>
                </div>
                <Table
                    :data="notifications.data" :fields="fields" :fieldBy="notificationsFilter.field"
                    :direction="notificationsFilter.direction" :loading="loading"
                    @changeField="changeField" @changeDirection="changeDirection">

                    <template v-slot:status="{ value }">
                        <div v-if="value" class="py-4 px-2 truncate">
                            <label class="p-1 rounded-full text-green-500 bg-green-200 uppercase border border-green-300">activa</label>
                        </div>
                        <div v-else class="py-4 px-2 truncate">
                            <label class="p-1 rounded-full text-red-500 bg-red-200 uppercase border border-red-300">inactiva</label>
                        </div>
                    </template>

                    <template v-slot:next_scheduled_date="{ fullRow, fieldIndex }">
                        <div class="py-4 px-2 truncate">
                            <label v-if="fullRow['periodicity_name'] !== 'interactive' && fullRow['status']">
                                {{ $dayjs(fullRow[fieldIndex]).format('DD-MM-YYYY') }} a las {{ fullRow['scheduled_hour'] }}:00 h
                            </label>
                        </div>
                    </template>

                </Table>
                <paginate v-if=notifications.meta :meta=notifications.meta @changePage="changePage"/>
                <PaginateTextInfo v-if="notifications.meta" :meta="notifications.meta" class="flex-auto text-center sm:hidden"/>
                <paginate-mobile :links="notifications.links" @changePage="changePage" class="sm:hidden"/>
            </div>
        </SectionWrapper>
    </All>
</template>

<script>
import {All} from "@/api/components";
import SectionWrapper from "@/pages/inside/shared/slots/SectionWrapper";
import Header from "@/pages/inside/shared/datatables/Header";
import Paginate from "@/pages/inside/shared/datatables/Paginate";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import PerPageSelector from "@/pages/inside/shared/datatables/PerPageSelector";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import Table from "@/pages/inside/sections/notifications/Table";
import ShowMod from "@/pages/inside/sections/notifications/modals/Show";
import NewMod from "@/pages/inside/sections/notifications/modals/New";
import DestroyMod from "@/pages/inside/sections/notifications/modals/Destroy";
import NotificatorMod from "@/pages/inside/sections/notifications/modals/Notificator";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
import {actions, state} from "@/store";

export default {
    name: "Notifications",
    components:{
        All, SectionWrapper, Header, Paginate, PaginateMobile, PerPageSelector, Searcher, Table, ShowMod, NewMod, DestroyMod, PaginateTextInfo, NotificatorMod
    },
    data() {
        return {
            title: 'Notificaciones',
            entity: 'Notificación',
            loading: false,
            notificationsFilter: {
                page: null,
                perPage: state.visibleRowsQtyInTable,
                field: 'name',
                direction: 'asc',
                text: null
            },
            fields,
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        },
        isInteractiveAndActive() {
            return this.elSelected.periodicity_name === 'interactive' && this.elSelected.status;
        }
    },
    mounted() {
        actions.setElSelected(false);
    },
    methods: {
        refreshDataTable(keepSelection = false) {
            if (!keepSelection) {
                actions.setElSelected(false);
            }
            this.$refs.notificationsRef.request();
        },

        changePage(page){
            this.notificationsFilter.page = page;
            this.refreshDataTable();
        },

        changePerPage(option){
            this.notificationsFilter.perPage = option;
            this.notificationsFilter.page = 1;
            this.refreshDataTable();
        },

        changeField(field){
            this.notificationsFilter.field = field;
            this.refreshDataTable();
        },

        changeDirection(direction){
            this.notificationsFilter.direction = direction;
            this.refreshDataTable();
        },

        changeText(text){
            this.notificationsFilter.page = null;
            this.notificationsFilter.text = text;
            this.refreshDataTable();
        },
    }
};

const fields = [
    {
        label: 'Notificación',
        key: 'name'
    },
    {
        label: 'Título',
        key: 'title'
    },
    {
        label: 'Cuerpo',
        key: 'body'
    },
    {
        label: 'Estado',
        key: 'status'
    },
    {
        label: 'Periodicidad',
        key: 'periodicity_es_name'
    },
    {
        label: 'Último envío',
        key: 'last_send_date'
    },
    {
        label: 'Próxima fecha programada',
        key: 'next_scheduled_date'
    },
];
</script>

<style scoped>

</style>