<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-plus text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'w-95por lg:w-70por'" :show="show" @close="show = false">
            <form @submit.prevent="store">
            <div class="flex flex-col overflow-y-scroll xl:overflow-y-hidden h-full">
                <ModalHeader :type="'new'" :entity="entity" @close="show = false"/>
                <div class="flex flex-col xl:flex-row overflow-y-scroll xl:overflow-y-hidden w-full">
                    <div class="flex flex-col w-full xl:w-1/2 justify-start items-center p-4 xl:overflow-y-auto h-auto xl:h-100 xl:border-r xl:border-gray-900">
                        <label class="mt-1 w-full max-w-lg">
                            <span>Nombre</span>
                            <input v-model="form.name" class="form-input mt-1 w-full text-bo-input" placeholder="Nombre" ref="nameRef" required>
                        </label>
                        <p class="mt-4 w-full text-center">Activa</p>
                        <RadioBinarySelector :parentValue="form.status" :editable="true" :trueAsDefault="true" @change="statusChanged"/>
                        <div v-if="form.status" class="flex flex-col w-full justify-start items-center">
                            <div class="mt-4 w-full max-w-lg">
                                <span>Periodicidad</span>
                                <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                    <div type="button" class="w-full text-left p-2 truncate" :class="form.periodicity_id ? 'text-bo-input' : 'text-gray-500'"
                                            @click="selectorToggle('periodicities')" ref="periodicitiesNameRef">
                                        {{ selectOptionMsg }}
                                    </div>
                                    <button type="button" @click="clear('periodicities', 'periodicity_id')"
                                            class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                        <i class="mdi mdi-close text-xl"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <transition name="slide-fade">
                            <div v-if="periodicitiesSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md max-w-lg">
                                <PeriodicitySelector @selection="selection" :prefix="'periodicities'" :target="'periodicity_id'"/>
                            </div>
                        </transition>

                        <div v-if="form.status" class="flex flex-row mt-4 w-full justify-between max-w-lg">
                            <label v-if="scheduled_hour_show" class="w-24">
                                <span class="font-normal capitalize">Hora</span>
                                <select v-model="form.scheduled_hour" class="form-select w-full text-center text-bo-input">
                                    <option v-for="n in options" :key="n" :value="n">{{ n }} h</option>
                                </select>
                            </label>
                            <div v-if="scheduled_date_show" class="flex flex-col w-50 justify-start items-start">
                                <span class="w-full text-left">Fecha de asignación</span>
                                <div class="w-full flex flex-row justify-start">
                                    <div class="relative mt-1">
                                        <div class="absolute flex border border-transparent left-0 top-0 h-full w-10">
                                            <div class="flex items-center justify-center rounded-tl rounded-bl z-10 bg-gray-100 text-gray-600 text-lg h-full w-full">
                                                <span class="mdi mdi-calendar"></span>
                                            </div>
                                        </div>
                                        <input v-model="assignedDateFormatted" @click="assignedDateCalendarToggle" v-on:keyup.space="assignedDateCalendarToggle" type="text"
                                               class="form-input w-full text-center text-bo-input p-0 h-10 cursor-pointer" placeholder="Fecha" readonly>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <transition name="slide-fade">
                            <div v-if="assignedDateCalendarExpanded" class="flex flex-row self-end justify-end mt-1 z-20">
                                <date-picker v-model='assignedDate' :min-date="$dayjs().add(1,'day').format('YYYY-MM-DD')"/>
                            </div>
                        </transition>
                        <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
                                    leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
                            <div v-show="assignedDateCalendarExpanded" class="z-10 fixed inset-0 transition-opacity">
                                <div @click="assignedDateCalendarToggle" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
                            </div>
                        </transition>

                    </div>
                    <div class="flex flex-col w-full xl:w-1/2 justify-start items-center p-4 xl:overflow-y-auto h-auto xl:h-100">
                        <div class="border border-gray-600 h-full max-w-lg p-4 py-14 rounded-3xl h-100 flex">
                            <div class="border border-gray-600 h-full max-w-lg p-4 pb-40 rounded-md flex flex-col">
                                <label class="mt-4 w-full">
                                    <span>Título</span>
                                    <input v-model="form.title" class="form-input mt-1 w-full text-center text-bo-input" placeholder="Título" ref="titleRef" required>
                                </label>
                                <label class="mt-10 w-full">
                                    <span>Mensaje</span>
                                    <textarea style="resize:none" rows="3" v-model="form.body" class="form-input mt-1 w-full text-center text-bo-input"
                                              placeholder="Mensaje" ref="bodyRef" required></textarea>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalFooter @close="show = false">
                <Request model="Notification" action="store" :form="form" @success="onSuccess" @error="onError" ref="storeNotificationRef"/>
                <ActionBtn :action="'store'" @store="store"/>
            </ModalFooter>
            </form>
        </ModalBase>
    </div>
</template>

<script>
import {Request} from "@/api/components";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";
import RadioBinarySelector from "@/pages/inside/shared/modals/RadioBinarySelector";
import PeriodicitySelector from "@/pages/inside/shared/modals/PeriodicitySelector";

export default {
    name: "New",
    components: {
        Request, ModalBase, ModalHeader, ModalFooter, ActionBtn, RadioBinarySelector, PeriodicitySelector
    },
    props: ['entity'],
    data() {
        return {
            show: false,
            form: {
                name: null,
                title: null,
                body: null,
                status: 1,
                periodicity_id: null,
                scheduled_hour: null,
                next_scheduled_date: null,
            },
            assignedDate: null,
            periodicitiesSelectorExpanded: false,
            assignedDateCalendarExpanded: false,
            options: [7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
        }
    },
    watch: {
        show(n) {
            if (!n) {
                this.resetForm();
            }
        },
        'form.periodicity_id': {
            handler(n){
                if (n === 1) { // si es asignada
                    this.form.scheduled_hour = null;
                }
                if (n !== 2) { // si no es interactiva
                    this.assignedDate = null;
                    this.form.next_scheduled_date = null;
                }
            }
        },
        assignedDateFormatted() {
            this.form.next_scheduled_date = this.assignedDate ? this.$dayjs(this.assignedDate).format('YYYY-MM-DD') : null;
            this.assignedDateCalendarExpanded = false;
        }
    },
    computed: {
        assignedDateFormatted() {
            return !this.assignedDate ? null : this.$dayjs(this.assignedDate).format('DD-MM-YYYY')
        },
        scheduled_date_show() {
            return this.form.periodicity_id === 2; // es assignada
        },
        scheduled_hour_show() {
            return !!(this.form.periodicity_id && this.form.periodicity_id !== 1); // no es interactiva
        }
    },
    methods: {
        store() {
            this.$refs.storeNotificationRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        onSuccess(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
            this.$emit('refreshDataTable');
            this.show = false;
        },
        selectorToggle(prefix) {
            this[prefix + 'SelectorExpanded'] = !this[prefix + 'SelectorExpanded'];
        },
        clear(prefix, target) {
            this.$refs[prefix + 'NameRef'].textContent = this.selectOptionMsg;
            this.form[target] = null;
            if (this[prefix + 'SelectorExpanded']) {
                this.selectorToggle(prefix + 'Selector');
            }
        },
        async selection(prefix, target, el) {
            this.$refs[prefix + 'NameRef'].textContent = el.es_name;
            this.form[target] = el.id;
            this.selectorToggle(prefix);
        },
        resetForm() {
            this.form.name = null;
            this.form.title = null;
            this.form.body = null;
            this.form.status = 1;
            this.form.periodicity_id = null;
            this.$refs['periodicitiesNameRef'].textContent = this.selectOptionMsg;
            this.assignedDate = null;
            this.assignedDateCalendarExpanded = false;
            this.periodicitiesSelectorExpanded = false;
        },
        statusChanged(status) {
            this.form.status = status === 'SI';
        },
        assignedDateCalendarToggle() {
            this.assignedDateCalendarExpanded = !this.assignedDateCalendarExpanded;
        },
    }
}
</script>

<style scoped>

</style>