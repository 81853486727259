<template>
    <All model="Periodicities" :immediate="true" v-slot="{data:periodicities}" :query="periodicitiesFilter"
         @loading:change="(event) => loading = event" ref="periodicitiesRef">
        <div class="flex flex-col w-full justfy-center items-end text-bo-input">
            <div class="flex flex-row w-full">
                <div class="w-1/3 flex justify-center sm:justify-center">
                </div>
                <div class="w-2/3">
                    <searcher @changeText="changeText"/>
                </div>
            </div>
            <PeriodicitySubTable :data="periodicities.data" :fields="fields" :loading="loading" :page="periodicitiesFilter.page" @selectRow="selectRow" class="mt-2"/>
            <div class="w-full flex flex-row flex-wrap justify-end items-center pt-1">
                <PaginateTextInfo v-if="periodicities.meta" :meta="periodicities.meta" class="flex-auto text-center"/>
                <!--                <PaginateLinks v-if="periodicities.meta" :meta="periodicities.meta" @changePage="changePage" class="hidden md:flex"/>-->
                <PaginateMobile v-if="periodicities.links" :links="periodicities.links" @changePage="changePage"/>
            </div>
        </div>
    </All>
</template>

<script>
import All from "@/api/components/All";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
import PeriodicitySubTable from "@/pages/inside/shared/datatables/PeriodicitySubTable";
export default {
    name: "PeriodicitySelector",
    props: ['prefix', 'target'],
    components: {
        PeriodicitySubTable, All, Searcher, PaginateMobile, PaginateTextInfo
    },
    data() {
        return {
            loading: false,
            periodicitiesFilter: {
                page: null,
                perPage: 10,
                field: 'position',
                direction: 'asc',
                text: null
            },
            el: null,
            fields,
        }
    },
    methods: {
        changePage(page){
            this.periodicitiesFilter.page = page;
            this.$refs.periodicitiesRef.request(); // Para hacer la request
        },
        changeText(text){
            this.periodicitiesFilter.page = null;
            this.periodicitiesFilter.text = text;
            this.$refs.periodicitiesRef.request(); // Para hacer la request
        },
        selectRow(el) {
            this.el = el;
            this.$emit('selection', this.prefix, this.target, this.el);
        },
    }
};

const fields = [
    {
        label: 'Periodicidad',
        key: 'es_name'
    },
    {
        label: 'Descripción',
        key: 'description'
    },
];
</script>

<style scoped>

</style>