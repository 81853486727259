var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('All',{ref:"notificationsRef",attrs:{"model":"Notification","immediate":true,"query":_vm.notificationsFilter},on:{"loading:change":function (event) { return _vm.loading = event; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var notifications = ref.data;
return [_c('SectionWrapper',[_c('div',{staticClass:"w-full px-1 sm:px-2 xl:px-5"},[_c('Header',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"py-2 w-full flex flex-row items-between justify-center"},[_c('perPageSelector',{on:{"changeOption":_vm.changePerPage}}),_c('div',{staticClass:"w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6"},[_c('new-mod',{attrs:{"entity":_vm.entity},on:{"refreshDataTable":_vm.refreshDataTable}}),(_vm.elSelected)?_c('show-mod',{attrs:{"entity":_vm.entity},on:{"refreshDataTable":_vm.refreshDataTable}}):_vm._e(),(_vm.elSelected)?_c('destroy-mod',{attrs:{"entity":_vm.entity},on:{"refreshDataTable":_vm.refreshDataTable}}):_vm._e(),(_vm.elSelected && _vm.isInteractiveAndActive)?_c('notificator-mod',{attrs:{"entity":_vm.entity},on:{"refreshDataTable":_vm.refreshDataTable}}):_vm._e()],1),_c('searcher',{on:{"changeText":_vm.changeText}})],1),_c('Table',{attrs:{"data":notifications.data,"fields":_vm.fields,"fieldBy":_vm.notificationsFilter.field,"direction":_vm.notificationsFilter.direction,"loading":_vm.loading},on:{"changeField":_vm.changeField,"changeDirection":_vm.changeDirection},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var value = ref.value;
return [(value)?_c('div',{staticClass:"py-4 px-2 truncate"},[_c('label',{staticClass:"p-1 rounded-full text-green-500 bg-green-200 uppercase border border-green-300"},[_vm._v("activa")])]):_c('div',{staticClass:"py-4 px-2 truncate"},[_c('label',{staticClass:"p-1 rounded-full text-red-500 bg-red-200 uppercase border border-red-300"},[_vm._v("inactiva")])])]}},{key:"next_scheduled_date",fn:function(ref){
var fullRow = ref.fullRow;
var fieldIndex = ref.fieldIndex;
return [_c('div',{staticClass:"py-4 px-2 truncate"},[(fullRow['periodicity_name'] !== 'interactive' && fullRow['status'])?_c('label',[_vm._v(" "+_vm._s(_vm.$dayjs(fullRow[fieldIndex]).format('DD-MM-YYYY'))+" a las "+_vm._s(fullRow['scheduled_hour'])+":00 h ")]):_vm._e()])]}}],null,true)}),(notifications.meta)?_c('paginate',{attrs:{"meta":notifications.meta},on:{"changePage":_vm.changePage}}):_vm._e(),(notifications.meta)?_c('PaginateTextInfo',{staticClass:"flex-auto text-center sm:hidden",attrs:{"meta":notifications.meta}}):_vm._e(),_c('paginate-mobile',{staticClass:"sm:hidden",attrs:{"links":notifications.links},on:{"changePage":_vm.changePage}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }