<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-send text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'w-95por lg:w-50por'" :show="show" @close="show = false">
            <Request model="Notification" action="byId" :form="notificationFilter" @success="onSuccessGetNotification" @error="onErrorGetNotification" ref="getNotificationRef"/>
            <div class="flex flex-col h-full">
                <ModalHeader :type="'send'" :entity="entity" @close="show = false"/>
                <div class="flex flex-col w-full justify-start items-center p-4 xl:overflow-y-auto h-auto xl:h-100">
                    <div class="border border-gray-600 h-full max-w-lg p-4 py-14 rounded-3xl h-100 flex">
                        <div class="border border-gray-600 h-full max-w-lg p-4 pb-40 rounded-md flex flex-col">
                            <label class="mt-4 w-full">
                                <span>Título</span>
                                <input v-model="title" class="form-input mt-1 w-full text-center text-bo-input" placeholder="Título" ref="titleRef" :disabled="true">
                            </label>
                            <label class="mt-10 w-full">
                                <span>Mensaje</span>
                                <textarea style="resize:none" rows="3" v-model="body" class="form-input mt-1 w-full text-center text-bo-input"
                                          placeholder="Mensaje" ref="bodyRef" :disabled="true"></textarea>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col p-4 sm:px-24">
                    <label class="mt-4">
                        <span>Para enviar la notificación presiona el botón de <span class="font-semibold">Enviar</span>.</span>
                    </label>
                </div>
            </div>
            <ModalFooter @close="show = false">
                <Request model="Notification" action="send" :form="form" @success="onSuccess" @error="onError" ref="sendNotificationRef"/>
                <ActionBtn :action="'send'" @send="send"/>
            </ModalFooter>
        </ModalBase>
    </div>
</template>

<script>
import {Request} from "@/api/components";
import {actions} from "@/store";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";
export default {
    name: "Notificator",
    props: [ 'entity' ],
    components: {
        Request, ModalBase, ModalFooter, ModalHeader, ActionBtn
    },
    data() {
        return {
            show: false,
            isEditable: false,
            form: {
                id: null,
            },
            notificationFilter: {
                id: null
            },
            title: null,
            body: null,
        }
    },
    watch: {
        show(n) {
            if (n) {
                this.notificationFilter.id = this.elSelected.id;
                this.$refs.getNotificationRef.request();
            }
        },
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        },
    },
    mounted() {
        let elSelected = actions.getElSelected();
        this.form.id  = elSelected.id;
    },
    methods: {
        send(){
            this.$refs.sendNotificationRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        onSuccess(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
            this.$emit('refreshDataTable');
            this.show = false;
        },
        onSuccessGetNotification(notification) {
            this.title = notification.title;
            this.body = notification.body;
        },
        onErrorGetNotification(errors) {
            this.toastErrors(errors)
        },
    }
}
</script>

<style scoped>

</style>