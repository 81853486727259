<template>
    <table class="w-full bg-white shadow-md table-fixed flex justify-center items-center text-gray-800">
        <i v-if="loading" class="mdi mdi-autorenew mdi-spin text-6xl absolute m-0 p-0 text-blue-cust opacity-50"></i>
        <tbody class="flex flex-col items-start justify-start w-full overflow-y-auto" style="height: 13.5rem;">
        <tr @click="clickOnRow(fullRow, rowIndex)" v-for="(fullRow,rowIndex) in data" :key="rowIndex" class="flex w-full cursor-pointer"
            :class="{
                'bo-even': rowIndex % 2 !== 0,
                'bo-odd': rowIndex % 2 === 0,
                'row-selected': rowIndex === rowSelected
            }">

            <td v-for="(field) in fields" :key="`col-${field.key}`"
                :style="field.key === 'description' ? 'width: '+colWidthLarge+'%;' : 'width: '+colWidth+'%;'"
            >
                <slot
                    :name="field.key"
                    :fieldIndex="field.key"
                    :fullRow="fullRow"
                    :value="fullRow[field.key]"
                    :fieldLabel="field.label"
                >
                    <p v-if="fullRow[field.key]" class="py-4 px-2 truncate">{{ fullRow[field.key] }}</p>
                </slot>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {actions} from "@/store";

export default {
    name: "PeriodicitySubTable",
    props: ['data', 'fields', 'loading', 'page', 'resetRowSelected'],
    data() {
        return {
            rowSelected: null,
        }
    },
    watch: {
        page() {
            this.rowSelected = null;
        },
        resetRowSelected() {
            this.rowSelected = null;
        }
    },
    computed: {
        colWidth() {
            return 25 / (Object.keys(this.fields).length - 1);
        },
        colWidthLarge() {
            return 75;
        },
    },
    methods: {
        clickOnRow(el, index) {
            if (index === this.rowSelected) {
                actions.setSubElSelected(null);
                this.rowSelected = null;
                this.$emit('selectRow', null);
            } else {
                actions.setSubElSelected(el);
                this.rowSelected = index;
                this.$emit('selectRow', el);
            }
        },
    }
}
</script>

<style scoped>

</style>